export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyBsKkezOqHChiTg05tOXbqEwc7r75dMeGk",
    authDomain: "snape-cookies.firebaseapp.com",
    databaseURL: "https://snape-cookies-default-rtdb.firebaseio.com",
    projectId: "snape-cookies",
    storageBucket: "snape-cookies.appspot.com",
    messagingSenderId: "903439747243",
    appId: "1:903439747243:web:453c62b9ecf946cbc94fee",
    measurementId: "G-K6BNWYJGGP"
  },
  baseUrl: 'https://39xj4qskj7.execute-api.ap-south-1.amazonaws.com/',
  memberSignup: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/prod/user',
  verifyEmail: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/prod/email-confirmation ',
  signIn: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/prod/signin',
  forgotPassword: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/prod/forget-password',
  confirmPassword: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/prod/confirm-password ',
  getProfile: "https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/prod/user ",
  flavour: 'https://7sbe4r5nb0.execute-api.us-east-2.amazonaws.com/prod/flavour ',
  favourite: 'https://zg8eoeiiz2.execute-api.us-east-2.amazonaws.com/prod/favourite',
  templates: 'https://dng7c1zt1j.execute-api.us-east-2.amazonaws.com/prod/templates',
  color: 'https://7sbe4r5nb0.execute-api.us-east-2.amazonaws.com/prod/color',
  product: 'https://8ihex1qk65.execute-api.us-east-2.amazonaws.com/prod/products',
  imageUpload: 'https://6cn2qm94lj.execute-api.us-east-2.amazonaws.com/image-upload/snape-cookie?file=',
  s3bucket: 'https://snape-cookie.s3.us-east-2.amazonaws.com/',
  about: 'https://df5qtwchle.execute-api.us-east-2.amazonaws.com/prod/ ',
  privacy: 'https://ejbka6l6v7.execute-api.us-east-2.amazonaws.com/prod/ ',
  terms: 'https://9657im5lgl.execute-api.us-east-2.amazonaws.com/prod/',
  faq: 'https://ri09cq2uob.execute-api.us-east-2.amazonaws.com/prod/',
  singleProduct: 'https://8ihex1qk65.execute-api.us-east-2.amazonaws.com/prod/single-product?id=',
  ledger: 'https://tpi4vbltj1.execute-api.us-east-2.amazonaws.com/prod/',
  dashboard: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/prod/orders/admin?',
  startDate: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/prod/orders/admin?start_date=',
  customer: 'https://l9jbttxtrj.execute-api.us-east-2.amazonaws.com/prod/admin/customer-list',
  updateStatus: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/prod/user/update-status',
  adminNotify: 'https://z2i19t7ici.execute-api.us-east-2.amazonaws.com/prod/admin-notifications',
  notification: 'https://z2i19t7ici.execute-api.us-east-2.amazonaws.com/notifications/',
  batchDelete: 'https://z2i19t7ici.execute-api.us-east-2.amazonaws.com/prod/admin-batch-delete',
  company: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/prod/company',
  company_signup: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/prod/company-signup',
  vendorById: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/prod/company?id=',
  company_details: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/prod/company/single-company-details?email_id=',

  edit_vendor: 'https://l9jbttxtrj.execute-api.us-east-2.amazonaws.com/prod/admin/edit-vendor',

  order_details: 'https://l9jbttxtrj.execute-api.us-east-2.amazonaws.com/prod/admin/order-details',
  orderId: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/prod/orders/order-details?id=',
  delivery_status: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/prod/change-delivery-status',

  item_status: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/prod/orders/item-status',
  order_status: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/prod/orders/order-status',

  admin_email: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/prod/company-contact?is_admin=true&email=',
  company_contact: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/prod/company-contact',
  comapny_document: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/prod/company/document',
  chat: 'https://evngndsua9.execute-api.us-east-2.amazonaws.com/prod/',

  message: 'https://evngndsua9.execute-api.us-east-2.amazonaws.com/prod/?id=',

  contactUs: 'https://puq9chx22m.execute-api.us-east-2.amazonaws.com/prod/contact-us ',
  generalquery: 'https://r6knfnh9u6.execute-api.us-east-2.amazonaws.com/prod/general-queries',
  newsletter: 'https://0ir44ib3pb.execute-api.us-east-2.amazonaws.com/prod/news-letter',
  boxcolor: 'https://8ihex1qk65.execute-api.us-east-2.amazonaws.com/prod/boxcolorupload',
  updateCookie: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/prod/updateordercookie',
  addCoupon: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/prod/couponcodediscount',
  generateCoupon: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/prod/autogeneratecouponcode',
  listCoupon: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/prod/listcouponcode',
  getCoupon: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/prod/couponcodediscount?id=',
  updateCoupon: '',
  cart: 'https://bqeejq5z3b.execute-api.us-east-2.amazonaws.com/prod/cart',
  userProducts: 'https://8ihex1qk65.execute-api.us-east-2.amazonaws.com/prod/user-products',
  bulkOrder:'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/prod/addbulkorder',
  orderCancel:'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/prod/ordercancellation',
  createZone: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/prod/zone',
  listZone: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/prod/listzones',
  updateZone: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/prod/zone',
  deleteZone: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/prod/zone',
};
